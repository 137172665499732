import { Component, OnInit } from "@angular/core";
import { PortfolioDataService } from "../../serviceLayer/portfolio-data.service";

@Component({
  selector: "app-portfolio",
  templateUrl: "./portfolio.component.html",
  styleUrls: ["./portfolio.component.css"],
})
export class PortfolioComponent implements OnInit {
  portfolioImages: Array<any> = [];
  constructor(public PDS: PortfolioDataService) {}

  ngOnInit() {
    this.portfolioImages = this.PDS.getPortfolioImages();
  }
}
