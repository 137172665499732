import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./views/home/home.component";
import { ContactUsComponent } from "./views/contact-us/contact-us.component";
import { EngineeringServicesComponent } from "./views/engineering-services/engineering-services.component";
import { ArchitecturalServicesComponent } from "./views/architectural-services/architectural-services.component";
import { WorkPortfolioComponent } from "./views/work-portfolio/work-portfolio.component";

const routes: Routes = [
  { path: "", component: HomeComponent, pathMatch: "full" },
  { path: "contact-us", component: ContactUsComponent },
  { path: "gallery", component: WorkPortfolioComponent },
  { path: "engineering-services", component: EngineeringServicesComponent },
  { path: "architectural-services", component: ArchitecturalServicesComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
