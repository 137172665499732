import { Component, OnInit } from "@angular/core";
import axios from "axios";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.css"],
})
export class ContactComponent implements OnInit {
  isSendingMail: boolean = false;
  isMailSent: boolean = false;
  isMailSendingError: boolean = false;
  mailSendingError: string = "";
  mobileNo: string = "";
  name: string = "";
  email: string = "";
  subject: string = "";
  message: string = "";
  constructor() {}

  ngOnInit() {}
  clearInputs() {
    this.name = "";
    this.mobileNo = "";
    this.email = "";
    this.subject = "";
    this.message = "";
  }
  sendEnquiryMail() {
    if (
      !this.name ||
      !this.mobileNo ||
      !this.email ||
      !this.subject ||
      !this.message
    ) {
      this.isMailSendingError = true;
      this.mailSendingError = "Please fill all the fields.";
      setTimeout(() => {
        this.isMailSendingError = false;
        this.mailSendingError = "";
      }, 5000);
      return;
    }

    const newEnquiry = {
      name: this.name,
      email: this.email,
      mobileNo: this.mobileNo,
      subject: this.subject,
      message: this.message,
    };
    this.isSendingMail = true;
    axios
      .post(
        "https://us-central1-trip-cart-7d21a.cloudfunctions.net/enquiryAndConfirmationMail",
        newEnquiry
      )
      .then((res) => {
        this.isSendingMail = false;
        this.isMailSent = true;
        this.clearInputs();
        setTimeout(() => {
          this.isMailSent = false;
        }, 5000);
        console.log(res.data);
      })
      .catch((err) => {
        this.isMailSendingError = true;
        this.mailSendingError = "Server Error. Please try after some time.";
        setTimeout(() => {
          this.isMailSendingError = false;
          this.mailSendingError = "";
        }, 5000);
      });
  }
}
