import { Inject, Component, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import * as $ from "jquery";
import {
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
  Router,
} from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "DynamicLaser";
  extraDiv: boolean = false;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router
  ) {}
  ngOnInit() {
    // this.document.body.classList.add("mobile-nav d-lg-none");
    $("html").scrollTop(0);

    this.router.events.subscribe((res) => {
      console.log(this.router.url);
    });
  }
}
