import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ServicesDataService {
  services: any = {
    "Engineering Services": {
      description:
        "Our team of machining experts at Dynamic Laser work together to provide unique solutions to difficult projects, as well as accuracy and efficiency to meet the deadlines that keep you awake at night. Supporting our customers is key to obtaining happy and lasting relationships with our client.",
      dataList: [
        // {
        //   name: "CNC Laser Cutting Services",
        //   description:
        //     "We can cut any material on our latest laser cutting machines like Trumpf, Amada, bystronic and many more.. We can cut MS upto 30mm, SS-25mm, AL-20mm, GI-20mm, and other material upto 20mm thickness at large scale as per customer requirements.",
        // },
        {
          name: "CNC Fibre Laser Cutting Services",
          description:
            "We can cut Mild Steel upto 10 mm thickness , We can cut Stainless Steel upto 5 mm thickness",
        },
        {
          name: "CNC Waterjet Cutting Services",
          description:
            "We can cut tiles, brass, copper, granite, stone, wood, mdf, cement tiles, aluminum, ss, ms at large scale upto 150mm",
        },
        {
          name: "CNC Wirecut Jobwork Services",
          description:
            "We have got the best wirecut setup in maharashtra with machines sodick and fanuc japan.",
        },
        {
          name: "CNC Bending Jobwork Services",
          description:
            "We have amada bending machine capacity upto 4m, A total solution with High speed and Accuracy for all production environments. User friendly! NC Controller , Front support table; Higher productivity!",
        },
        {
          name: "CNC Pipe and Tube Cutting Services",
          description:
            "We have amada cnc pipe cutting machine that removes material from pipe or tube to create desired profile.Typical profiles include straight cuts, mitres, saddles and midsection holes.",
        },
        {
          name: "CNC EDM Jobwork Services",
          description:
            "Electrical discharge machining (EDM), also known as spark machining, spark eroding, burning, die sinking, wire burning or wire erosion, is a manufacturing process whereby a desired shape is obtained by using electrical discharges (sparks).",
        },
        {
          name: "CNC Laser Marking Services",
          description:
            "Our laser marking machine which mainly involves the marking of work pieces using a laser. Almost any materials can be marked with a laser.",
        },
        {
          name: "Fabrication Services",
          description:
            "Our fabrication shop will bid on a job, usually based on the engineering drawings, and if awarded the contract will build the product. Large fab shops will employ a multitude of value added processes in one plant.",
        },
      ],
    },
    "Architectural Services": {
      description:
        "Dynamic Laser is a leading architectural service provider for Architects , Interior designers , and even individual customers. We work from concepts , photos , even from rough ideas , and deliver your job with highest quality",
      dataList: [
        {
          name: "Decorative Screens & Jaali Designs",
          description:
            "Decorative Screens & Jaali Designs : Laser Shop for laser cut wall art, the place to express your creativity through the cutting, buying and selling of handmade and vintage goods.",
        },
        {
          name: "CNC Laser Cut Wall Arts",
          description:
            "Laser cut wall arts made using the latest CNC laser cutting, router and printing technology and that is just the beginning. It is the easiest and most cost effective way",
        },
        {
          name: "Cutting and Carvings",
          description:
            "Our proficiency lies in offering optimum quality of carving services. We have the largest collection of 3d carving designs with over 1000+ designs. ",
        },
        {
          name: "Laser Cut Metallic Sculptures",
          description:
            "We design and make metallic sculptures with the help of our talented and experienced designers. To design and implement these metallic sculpture we use sophisticated software and laser cut machines.",
        },
        {
          name: "Canopy & Window Screens",
          description:
            "Canopy screens offer shade to outdoor sitting areas. They also create dynamic and shifting shadows as the light moves throughout the day. Canopy screens make areas stand out and add value to your home.",
        },
        {
          name: "CNC Laser Cut Decorative Door and Gate Designs",
          description:
            "We at provide innovative and unique solutions on designing your doors for your homes, offices, main entrance gate, main entrance door, etc. Available in various styles our doors exhibit fine workmanship.",
        },
        {
          name: "Signage & Name Plates",
          description:
            "We provide unique and creative solution to create and make letters as well as numbers for your home as well as at your office. We provide letter cutting services which are precision laser cut to your specifications.",
        },
        {
          name: "CNC Waterjet Granite and Marble Inlay Flooring",
          description:
            "For Granite Inlay flooring work we use high quality waterjet cutting machines",
        },
        {
          name: "Custom Laser Cut Products",
          description:
            "Want something unique and special to your home as well as office, we offer various designs for our customers to customize and present various designs in a form which were newer possible before. ",
        },
        {
          name: "UV Printing",
          description:
            "Papers and boards are the limitations for most printing companies, but we can apply our UV Flatbed services to a wide variety of different and specialist materials. We can confidently print onto substrates that the vast majority of cannot. ",
        },
      ],
    },
  };
  constructor() {}
  getServicesData(servicesName) {
    return this.services[servicesName];
  }
}
