import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-architectural-services",
  templateUrl: "./architectural-services.component.html",
  styleUrls: ["./architectural-services.component.css"],
})
export class ArchitecturalServicesComponent implements OnInit {
  servicesName: string = "Architectural Services";
  constructor() {}

  ngOnInit() {}
}
