import { Inject, Component, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import {
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
  Router,
} from "@angular/router";
import * as $ from "jquery";
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit {
  scrolled: boolean = true;
  home: string = "/";

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router
  ) {}

  ngOnInit() {
    this.mobileNavigation();
  }

  mobileNavigation() {
    // Mobile Navigation
    if ($(".nav-menu").length) {
      var $mobile_nav = $(".nav-menu").clone().prop({
        class: "mobile-nav d-lg-none",
      });

      [...$mobile_nav[0].firstChild.children].forEach((element) => {
        let elementTochange = element.children[0];
        let attributeValueToAdd = elementTochange.getAttribute("routerlink");
        elementTochange.setAttribute(
          "ng-reflect-router-link",
          attributeValueToAdd
        );
        elementTochange.setAttribute("href", attributeValueToAdd);
      });

      $("body").append($mobile_nav);
      $("body").prepend(
        '<button type="button" class="mobile-nav-toggle d-lg-none"><i class="icofont-navigation-menu"></i></button>'
      );
      $("body").append('<div class="mobile-nav-overly"></div>');

      $(this.document).on("click", ".mobile-nav-toggle", function (e) {
        $("body").toggleClass("mobile-nav-active");
        $(".mobile-nav-toggle i").toggleClass(
          "icofont-navigation-menu icofont-close"
        );
        $(".mobile-nav-overly").toggle();
      });

      $(this.document).on("click", ".mobile-nav .drop-down > a", function (e) {
        e.preventDefault();
        $(this).next().slideToggle(300);
        $(this).parent().toggleClass("active");
      });

      $(this.document).click(function (e) {
        var container = $(".mobile-nav, .mobile-nav-toggle");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          if ($("body").hasClass("mobile-nav-active")) {
            $("body").removeClass("mobile-nav-active");
            $(".mobile-nav-toggle i").toggleClass(
              "icofont-navigation-menu icofont-close"
            );
            $(".mobile-nav-overly").fadeOut();
          }
        }
      });
    } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
      $(".mobile-nav, .mobile-nav-toggle").hide();
    }

    this.router.events.subscribe((res) => {
      if (this.router.url !== "/") {
        this.scrolled = true;
        $("html").scrollTop(0);
      } else {
        this.scrolled = false;
        $("html").scrollTop(0);
      }
    });
  }
}
