import { Component, OnInit, ViewChild } from "@angular/core";
import * as $ from "jquery";
@Component({
  selector: "app-facts",
  templateUrl: "./facts.component.html",
  styleUrls: ["./facts.component.css"],
})
export class FactsComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    // jQuery counterUp (used in Facts section)
    $(".counter").counterUp({
      delay: 10,
      time: 1000,
    });
  }
}
