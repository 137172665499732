import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-engineering-services",
  templateUrl: "./engineering-services.component.html",
  styleUrls: ["./engineering-services.component.css"],
})
export class EngineeringServicesComponent implements OnInit {
  servicesName: string = "Engineering Services";

  constructor() {}

  ngOnInit() {}
}
