import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
// --------------------------------------------------------------------------------------------------------------
import { AboutComponent } from "./components/about/about.component";
import { CarouselComponent } from "./components/carousel/carousel.component";
import { ContactComponent } from "./components/contact/contact.component";
import { CtaComponent } from "./components/cta/cta.component";
import { FactsComponent } from "./components/facts/facts.component";
import { FeaturedServicesComponent } from "./components/featured-services/featured-services.component";
import { FooterComponent } from "./components/footer/footer.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { PortfolioComponent } from "./components/portfolio/portfolio.component";
import { ServicesComponent } from "./components/services/services.component";
// --------------------------------------------------------------------------------------------------------------
import { PortfolioDataService } from "./serviceLayer/portfolio-data.service";
import { ServicesDataService } from "./serviceLayer/services-data.service";
// --------------------------------------------------------------------------------------------------------------
import { ArchitecturalServicesComponent } from "./views/architectural-services/architectural-services.component";
import { ContactUsComponent } from "./views/contact-us/contact-us.component";
import { EngineeringServicesComponent } from "./views/engineering-services/engineering-services.component";
import { HomeComponent } from "./views/home/home.component";
import { WorkPortfolioComponent } from "./views/work-portfolio/work-portfolio.component";

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    ContactComponent,
    PortfolioComponent,
    ServicesComponent,
    AboutComponent,
    CtaComponent,
    CarouselComponent,
    FeaturedServicesComponent,
    HomeComponent,
    ContactUsComponent,
    FactsComponent,
    EngineeringServicesComponent,
    ArchitecturalServicesComponent,
    WorkPortfolioComponent,
  ],
  imports: [BrowserModule, AppRoutingModule, FormsModule],
  providers: [ServicesDataService, PortfolioDataService],
  bootstrap: [AppComponent],
})
export class AppModule {}
