import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PortfolioDataService {
  portfolioImages: Array<any> = [
    { name: "Machine", url: "assets/img/portfolio/PortFolio-1.jpg" },
    { name: "Machine", url: "assets/img/portfolio/PortFolio-2.jpg" },
    { name: "Machine", url: "assets/img/portfolio/PortFolio-3.jpg" },
    { name: "Machine", url: "assets/img/portfolio/PortFolio-4.jpg" },
    { name: "Work-Sample", url: "assets/img/portfolio/PortFolio-5.jpg" },
    { name: "Work-Sample", url: "assets/img/portfolio/PortFolio-6.jpg" },
    { name: "Work-Sample", url: "assets/img/portfolio/PortFolio-7.jpg" },
    { name: "Work-Sample", url: "assets/img/portfolio/PortFolio-8.jpg" },
    { name: "Work-Sample", url: "assets/img/portfolio/PortFolio-9.jpg" },
    { name: "Work-Sample", url: "assets/img/portfolio/PortFolio-10.jpg" },
    { name: "Work-Sample", url: "assets/img/portfolio/PortFolio-11.jpg" },
    { name: "Work-Sample", url: "assets/img/portfolio/PortFolio-12.jpg" },
    { name: "Work-Sample", url: "assets/img/portfolio/PortFolio-13.jpg" },
    { name: "Work-Sample", url: "assets/img/portfolio/PortFolio-14.jpg" },
    { name: "Work-Sample", url: "assets/img/portfolio/PortFolio-15.jpg" },
  ];

  constructor() {}
  getPortfolioImages() {
    return this.portfolioImages;
  }
}
