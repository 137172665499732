import { Component, OnInit, Input } from "@angular/core";
import { ServicesDataService } from "../../serviceLayer/services-data.service";

@Component({
  selector: "app-services",
  templateUrl: "./services.component.html",
  styleUrls: ["./services.component.css"],
})
export class ServicesComponent implements OnInit {
  @Input() servicesName: string;
  services: Array<any> = [];
  servicesDescription: string = "";
  constructor(private SD: ServicesDataService) {}

  ngOnInit() {
    this.services = this.SD.getServicesData(this.servicesName).dataList;
    this.servicesDescription = this.SD.getServicesData(
      this.servicesName
    ).description;
  }
  getFormattedDescriptionText(text) {
    let textArray = text.split("Dynamic Laser");
    return `${textArray[0]} <b>Dynamic Laser</b> ${textArray[1]}`;
  }
}
